import getUrlParams from './getUrlParams'

function checkUtmParams() {
  if (typeof window === 'undefined') {
    return
  }

  const ref = getUrlParams('ref')

  if (ref) {
    localStorage.setItem('ref', ref)
  } else {
    let utm = ''
    const utmSource = getUrlParams('utm_source')
    const utmMedium = getUrlParams('utm_medium')
    const utmCampaign = getUrlParams('utm_campaign')
    const utmTerm = getUrlParams('utm_term')
    const utmContent = getUrlParams('utm_content')

    if (utmSource) {
      utm += `utm_source=${utmSource}`
    }

    if (utmMedium) {
      utm += `&utm_medium=${utmMedium}`
    }

    if (utmCampaign) {
      utm += `&utm_campaign=${utmCampaign}`
    }

    if (utmTerm) {
      utm += `&utm_term=${utmTerm}`
    }

    if (utmContent) {
      utm += `&utm_content=${utmContent}`
    }

    if (utm) {
      localStorage.setItem('ref', utm)
    }
  }
}

export default checkUtmParams
