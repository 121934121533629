import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { useState } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
`

const IconContainer = styled.div`
  position: absolute;
  top: 13px;
  right: 12px;
`

const Select = styled.select`
  width: 100%;
  transition: all 200ms;
  padding: 9px 12px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  border-radius: 5px;
  color: #afafaf;
  background: #ffffff;
  font-size: 16px;
  line-height: 28px;
  --moz-appearance: none;
  --webkit-appearance: none;
  appearance: none;
  outline: none;
  &:hover,
  &:focus {
    border: 1px solid #000000;
  }
`

const CaretDownIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 9L12 15L18 9"
      stroke="#AFAFAF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

const CaretUpIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 15L12 9L18 15"
      stroke="#AFAFAF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

const BasicSelect = ({
  placeholder = '',
  style = {},
  options = [],
  value,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Container>
      <Select
        value={value}
        name="select"
        id="select"
        placeholder={placeholder}
        style={{
          color: value ? '#000000' : '#afafaf',
          ...style,
        }}
        onChange={event => {
          setIsOpen(false)
          onChange(event)
        }}
        onClick={() => {
          setIsOpen(!isOpen)
        }}
        onBlur={() => {
          setIsOpen(false)
        }}
      >
        <option value="">{placeholder}</option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      <IconContainer>
        <CaretDownIcon />
        {/* <AnimatePresence>
          {isOpen ? (
            <motion.div
              exit={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              transition={{
                duration: 0.2,
                ease: 'easeOut',
              }}
            >
              <CaretUpIcon />
            </motion.div>
          ) : (
            <motion.div
              exit={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              transition={{
                duration: 0.2,
                ease: 'easeOut',
              }}
            >
              <CaretDownIcon />
            </motion.div>
          )}
        </AnimatePresence> */}
      </IconContainer>
    </Container>
  )
}

export default BasicSelect
