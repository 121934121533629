import { sample } from 'lodash'

export const presetColorPallete = [
  'hsla(360, 100%, 53%, 1)',
  'hsla(122, 100%, 53%, 1)',
  'hsla(191, 100%, 53%, 1)',
  'hsla(317, 100%, 53%, 1)',
  'hsla(245, 100%, 53%, 1)',
  'hsla(32, 100%, 53%, 1)',
  'hsla(76, 100%, 53%, 1)',
  'hsla(173, 100%, 53%, 1)',
  'hsla(277, 100%, 53%, 1)',
  'hsla(0, 0%, 11%, 1)',
]

export const departmentStreamsMap = {
  general: [
    {
      pictureGradient: 'deep_space',
      name: 'General',
      description: 'A place to keep us streamlined company-wide',
      color: sample(presetColorPallete),
    },
  ],
  engineering: [
    {
      pictureGradient: null,
      name: 'Engineering Updates',
      description: 'A place to keep us streamlined for Engineering Updates',
    },
    {
      pictureGradient: null,
      name: 'Engineering Daily Standup',
      description:
        'A place to keep us streamlined for Engineering Daily Standup',
    },
    // {
    //   pictureGradient: null,
    //   name: 'Eng Meeting Notes',
    //   description: 'A place to keep us streamlined for Eng Meeting Notes',
    // },
    // {
    //   pictureGradient: null,
    //   name: 'Engineering Discussions',
    //   description:
    //     'A place to keep us streamlined for Engineering Discussions',
    // },
    // {
    //   pictureGradient: null,
    //   name: 'Product Proposals',
    //   description: 'A place to keep us streamlined for Product Proposals',
    // },
    // {
    //   pictureGradient: null,
    //   name: 'Product Updates',
    //   description: 'A place to keep us streamlined for Product Updates',
    // },
    // {
    //   pictureGradient: null,
    //   name: 'Engineering Ops',
    //   description: 'A place to keep us streamlined for Engineering Ops',
    // },
    // {
    //   pictureGradient: null,
    //   name: 'Infrastructure',
    //   description: 'A place to keep us streamlined for Infrastructure',
    // },
  ],
  leadership: [
    {
      pictureGradient: null,
      name: 'Ceo Newsletter',
      description: 'A place to keep us streamlined for Ceo Newsletter',
    },
    {
      pictureGradient: null,
      name: 'Leadership Assessments',
      description: 'A place to keep us streamlined for Leadership Assessments',
    },
    {
      pictureGradient: null,
      name: 'Leadership Memos',
      description: 'A place to keep us streamlined for Leadership Memos',
    },
    // {
    //   pictureGradient: null,
    //   name: 'Global Announcements',
    //   description: 'A place to keep us streamlined for Global Announcements',
    // },
    // {
    //   pictureGradient: null,
    //   name: 'Company Updates',
    //   description: 'A place to keep us streamlined for Company Updates',
    // },
    // {
    //   pictureGradient: null,
    //   name: 'Ceo Weekly Updates',
    //   description: 'A place to keep us streamlined for Ceo Weekly Updates',
    // },
    // {
    //   pictureGradient: null,
    //   name: 'Company Strategies',
    //   description: 'A place to keep us streamlined for Company Strategies',
    // },
    // {
    //   pictureGradient: null,
    //   name: 'Board Meetings',
    //   description: 'A place to keep us streamlined for Board Meetings',
    // },
  ],
  marketing: [
    {
      pictureGradient: null,
      name: 'Growth Experiments',
      description: 'A place to keep us streamlined for Growth Experiments',
    },
    {
      pictureGradient: null,
      name: 'Marketing Updates',
      description: 'A place to keep us streamlined for Marketing Updates',
    },
    {
      pictureGradient: null,
      name: 'Marketing Discussions',
      description: 'A place to keep us streamlined for Marketing Discussions',
    },
    //   {
    //     pictureGradient: null,
    //     name: 'Marketing Brainstorming',
    //     description:
    //       'A place to keep us streamlined for Marketing Brainstorming',
    //   },
    //   {
    //     pictureGradient: null,
    //     name: 'Marketing Proposal',
    //     description: 'A place to keep us streamlined for Marketing Proposal',
    //   },
    //   {
    //     pictureGradient: null,
    //     name: 'Data',
    //     description: 'A place to keep us streamlined for Data',
    //   },
    //   {
    //     pictureGradient: null,
    //     name: 'Mkt Meeting Notes',
    //     description: 'A place to keep us streamlined for Mkt Meeting Notes',
    //   },
    //   {
    //     pictureGradient: null,
    //     name: 'Words',
    //     description: 'A place to keep us streamlined for Words',
    //   },
    //   {
    //     pictureGradient: null,
    //     name: 'Marketing Ops',
    //     description: 'A place to keep us streamlined for Marketing Ops',
    //   },
    // ],
    // customer_success: [
    //   {
    //     pictureGradient: null,
    //     name: 'Customer Data',
    //     description: 'A place to keep us streamlined for Customer Data',
    //   },
    //   {
    //     pictureGradient: null,
    //     name: 'Monthly Reports',
    //     description: 'A place to keep us streamlined for Monthly Reports',
    //   },
    //   {
    //     pictureGradient: null,
    //     name: 'Customer Feedback',
    //     description: 'A place to keep us streamlined for Customer Feedback',
    //   },
    //   {
    //     pictureGradient: null,
    //     name: 'Customer Calls',
    //     description: 'A place to keep us streamlined for Customer Calls',
    //   },
    //   {
    //     pictureGradient: null,
    //     name: 'Cs Meeting Notes',
    //     description: 'A place to keep us streamlined for Cs Meeting Notes',
    //   },
    //   {
    //     pictureGradient: null,
    //     name: 'Cs Sales Sync',
    //     description: 'A place to keep us streamlined for Cs Sales Sync',
    //   },
  ],
  customer_support: [
    {
      pictureGradient: null,
      name: 'Customer Data',
      description: 'A place to keep us streamlined for Customer Data',
    },
    {
      pictureGradient: null,
      name: 'Monthly Reports',
      description: 'A place to keep us streamlined for Monthly Reports',
    },
    {
      pictureGradient: null,
      name: 'Customer Feedback',
      description: 'A place to keep us streamlined for Customer Feedback',
    },
    // {
    //   pictureGradient: null,
    //   name: 'Customer Calls',
    //   description: 'A place to keep us streamlined for Customer Calls',
    // },
    // {
    //   pictureGradient: null,
    //   name: 'Cs Meeting Notes',
    //   description: 'A place to keep us streamlined for Cs Meeting Notes',
    // },
    // {
    //   pictureGradient: null,
    //   name: 'Cs Updates',
    //   description: 'A place to keep us streamlined for Cs Updates',
    // },
  ],
  operations: [
    {
      pictureGradient: null,
      name: 'People Ops',
      description: 'A place to keep us streamlined for People Ops',
    },
    {
      pictureGradient: null,
      name: 'Media Ops',
      description: 'A place to keep us streamlined for Media Ops',
    },
    {
      pictureGradient: null,
      name: 'Product Ops',
      description: 'A place to keep us streamlined for Product Ops',
    },
    // {
    //   pictureGradient: null,
    //   name: 'Internal Tools',
    //   description: 'A place to keep us streamlined for Internal Tools',
    // },
    // {
    //   pictureGradient: null,
    //   name: 'Ops Discussions',
    //   description: 'A place to keep us streamlined for Ops Discussions',
    // },
    // {
    //   pictureGradient: null,
    //   name: 'Staff Updates',
    //   description: 'A place to keep us streamlined for Staff Updates',
    // },
  ],
  hr: [
    // {
    //   pictureGradient: null,
    //   name: 'Recognitions',
    //   description: 'A place to keep us streamlined for Recognitions',
    // },
    // {
    //   pictureGradient: null,
    //   name: 'People Ops',
    //   description: 'A place to keep us streamlined for People Ops',
    // },
    // {
    //   pictureGradient: null,
    //   name: 'Internal Tools',
    //   description: 'A place to keep us streamlined for Internal Tools',
    // },
    {
      pictureGradient: null,
      name: 'Newjoiners',
      description: 'A place to keep us streamlined for Newjoiners',
    },
    {
      pictureGradient: null,
      name: 'Work Policies',
      description: 'A place to keep us streamlined for Work Policies',
    },
    {
      pictureGradient: null,
      name: 'Staff Updates',
      description: 'A place to keep us streamlined for Staff Updates',
    },
  ],
  product: [
    {
      pictureGradient: null,
      name: 'Product Updates',
      description: 'A place to keep us streamlined for Product Updates',
    },
    {
      pictureGradient: null,
      name: 'Product Proposals',
      description: 'A place to keep us streamlined for Product Proposals',
    },
    {
      pictureGradient: null,
      name: 'Internal Changelog',
      description: 'A place to keep us streamlined for Internal Changelog',
    },
    // {
    //   pictureGradient: null,
    //   name: 'Product Brainstorming',
    //   description: 'A place to keep us streamlined for Product Brainstorming',
    // },
    // {
    //   pictureGradient: null,
    //   name: 'Pricing',
    //   description: 'A place to keep us streamlined for Pricing',
    // },
    // {
    //   pictureGradient: null,
    //   name: 'Product Strategy',
    //   description: 'A place to keep us streamlined for Product Strategy',
    // },
    // {
    //   pictureGradient: null,
    //   name: 'Product Data',
    //   description: 'A place to keep us streamlined for Product Data',
    // },
    // {
    //   pictureGradient: null,
    //   name: 'Product Feedback',
    //   description: 'A place to keep us streamlined for Product Feedback',
    // },
    // {
    //   pictureGradient: null,
    //   name: 'User Interviews',
    //   description: 'A place to keep us streamlined for User Interviews',
    // },
    // {
    //   pictureGradient: null,
    //   name: 'Product Meeting Notes',
    //   description: 'A place to keep us streamlined for Product Meeting Notes',
    // },
  ],
  design: [
    // {
    //   pictureGradient: null,
    //   name: 'Pre Mockups',
    //   description: 'A place to keep us streamlined for Pre Mockups',
    // },
    // {
    //   pictureGradient: null,
    //   name: 'Post Mockups',
    //   description: 'A place to keep us streamlined for Post Mockups',
    // },
    // {
    //   pictureGradient: null,
    //   name: 'Design Ops',
    //   description: 'A place to keep us streamlined for Design Ops',
    // },
    // {
    //   pictureGradient: null,
    //   name: 'Design Log',
    //   description: 'A place to keep us streamlined for Design Log',
    // },
    {
      pictureGradient: null,
      name: 'Design Updates',
      description: 'A place to keep us streamlined for Design Updates',
    },
    {
      pictureGradient: null,
      name: 'Design Discussions',
      description: 'A place to keep us streamlined for Design Discussions',
    },
    {
      pictureGradient: null,
      name: 'Design Proposals',
      description: 'A place to keep us streamlined for Design Proposals',
    },
    // {
    //   pictureGradient: null,
    //   name: 'Critiquery',
    //   description: 'A place to keep us streamlined for Critiquery',
    // },
    // {
    //   pictureGradient: null,
    //   name: 'Design System Updates',
    //   description: 'A place to keep us streamlined for Design System Updates',
    // },
    // {
    //   pictureGradient: null,
    //   name: 'Insights',
    //   description: 'A place to keep us streamlined for Insights',
    // },
    // {
    //   pictureGradient: null,
    //   name: 'User Interviews',
    //   description: 'A place to keep us streamlined for User Interviews',
    // },
    // {
    //   pictureGradient: null,
    //   name: 'Design Meeting Notes',
    //   description: 'A place to keep us streamlined for Design Meeting Notes',
    // },
  ],
  sales: [
    {
      pictureGradient: null,
      name: 'Sales Insights',
      description: 'A place to keep us streamlined for Sales Insights',
    },
    {
      pictureGradient: null,
      name: 'Sales Meeting Notes',
      description: 'A place to keep us streamlined for Sales Meeting Notes',
    },
    {
      pictureGradient: null,
      name: 'Customer Demos',
      description: 'A place to keep us streamlined for Customer Demos',
    },
    // {
    //   pictureGradient: null,
    //   name: 'Sales Learnings',
    //   description: 'A place to keep us streamlined for Sales Learnings',
    // },
    // {
    //   pictureGradient: null,
    //   name: 'Sales Updates',
    //   description: 'A place to keep us streamlined for Sales Updates',
    // },
  ],
  other: [
    {
      pictureGradient: null,
      name: 'Weekly Updates',
      description: 'A place to keep us streamlined for Weekly Updates',
    },
    {
      pictureGradient: null,
      name: 'Meeting Notes',
      description: 'A place to keep us streamlined for Meeting Notes',
    },
    {
      pictureGradient: null,
      name: 'Discussions',
      description: 'A place to keep us streamlined for Discussions',
    },
    // {
    //   pictureGradient: null,
    //   name: 'Proposals',
    //   description: 'A place to keep us streamlined for Proposals',
    // },
  ],
}

export const departmentNames = [
  'Engineering',
  'Leadership',
  'Marketing',
  'Customer Success',
  'Customer Support',
  'Operations',
  'HR',
  'Product',
  'Design',
  'Sales',
  'Other',
]

export const pictureGradientPresets = [
  'a_lost_memory',
  'ali',
  'alihossein',
  'almost',
  'amethyst',
  'aqua_marine',
  'aqualicious',
  'army',
  'ash',
  'aubergine',
  'autumn',
  'azure_pop',
  'back_to_earth',
  'back_to_the_future',
  'behongo',
  'between_night_and_day',
  'between_the_clouds',
  'black_rose',
  'blood_red',
  'bloody_mary',
  'blurry_beach',
  'blush',
  'bora_bora',
  'bourbon',
  'brady_brady_fun_fun',
  'bright_vault',
  'calm_darya',
  'candy',
  'cheer_up_emo_kid',
  'cherry',
  'cherryblossoms',
  'christmas',
  'clear_sky',
  'cool_brown',
  'cosmic_fusion',
  'crazy_orange_i',
  'curiosity_blue',
  'dance_to_forget',
  'dania',
  'dark_knight',
  'dark_skies',
  'dawn',
  'day_tripper',
  'decent',
  'deep_purple',
  'deep_sea_space',
  'deep_space',
  'dirty_fog',
  'disco',
  'dracula',
  'dusk',
  'dusk_alt',
  'earthly',
  'eds_sunset_gradient',
  'eighties_purple',
  'electric_violet',
  'emerald_water',
  'endless_river',
  'facebook_messenger',
  'fifty_shades_of_grey',
  'firewatch',
  'flickr',
  'forest',
  'forever_lost',
  'fresh_turboscent',
  'friday',
  'frost',
  'frozen',
  'grapefruit_sunset',
  'green_and_blue',
  'green_beach',
  'green_to_dark',
  'haikus',
  'harmonic_energy',
  'hersheys',
  'horizon',
  'ibiza_sunset',
  'iiit_delhi',
  'inbox',
  'influenza',
  'instagram',
  'intuitive_purple',
  'joomla',
  'juicy_orange',
  'kashmir',
  'koko_caramel',
  'kyoto',
  'lemon_twist',
  'light_orange',
  'limeade',
  'little_leaf',
  'lizard',
  'love_couple',
  'lush',
  'man_of_steel',
  'mango_pulp',
  'mantle',
  'martini',
  'master_card',
  'mauve',
  'miaka',
  'miami_dolphins',
  'midnight_city',
  'mild',
  'minimal_red',
  'minnesota_vikings',
  'mirage',
  'misty_meadow',
  'mojito',
  'moonrise',
  'moor',
  'moss',
  'mystic',
  'namn',
  'neon_life',
  'nepal',
  'netflix',
  'nighthawk',
  'noon_to_dusk',
  'opa',
  'pale_wood',
  'parklife',
  'passion',
  'pastel_orange_at_the_sun',
  'peach',
  'petrichor',
  'piglet',
  'pinky',
  'pinot_noir',
  'pizelex',
  'playing_with_reds',
  'politics',
  'poncho',
  'predawn',
  'purple_bliss',
  'purple_paradise',
  'purple_white',
  'purplin',
  'red_mist',
  'red_ocean',
  'reef',
  'rose_water',
  'royal',
  'sage_persuasion',
  'sea_blizz',
  'sea_weed',
  'servquick',
  'shadow_night',
  'shahabi',
  'sherbert',
  'shore',
  'shrimpy',
  'shroom_haze',
  'snapchat',
  'solid_vault',
  'soundcloud',
  'starfall',
  'steel_gray',
  'stellar',
  'sun_on_the_horizon',
  'sunny_days',
  'sunrise',
  'sunset',
  'superman',
  'suzy',
  'sweet_morning',
  'sylvia',
  'talking_to_mice_elf',
  'teal_love',
  'the_strain',
  'timber',
  'titanium',
  'tranquil',
  'transfile',
  'turquoise_flow',
  'twitch',
  'ukraine',
  'vasily',
  'venice_blue',
  'vine',
  'virgin',
  'virgin_america',
  'winter',
  'youtube',
]

export const useCases = [
  { label: 'Internal newsletters', value: 'internal_newsletters' },
  { label: 'Department-wide updates', value: 'department_wide_updates' },
  { label: 'Team weekly memos', value: 'team_weekly_memos' },
  {
    label: 'Forum & Long form discussions',
    value: 'forum_and_long_form_discussions',
  },
  {
    label: 'Directory of people and information',
    value: 'directory_of_people_and_information',
  },
  { label: 'Company-wide announcements', value: 'company_wide_announcements' },
  {
    label: 'Cross-functional team updates',
    value: 'cross_functional_team_updates',
  },
  { label: 'Team-wide announcements', value: 'team_wide_announcements' },
  { label: 'Leadership assestment', value: 'leadership_assestment' },
  {
    label: 'Slack complement for async discussions',
    value: 'slack_complement_for_async_discussions',
  },
  { label: 'Project-level news', value: 'project_level_news' },
  { label: 'Internal decision log', value: 'internal_decision_log' },
  { label: 'Transprent meeting notes', value: 'transprent_meeting_notes' },
  { label: 'Daily standups notes', value: 'daily_standups_notes' },
  { label: 'Retrospectives', value: 'retrospectives' },
  { label: 'Project briefs', value: 'project_briefs' },
  { label: 'Progress updates', value: 'progress_updates' },
  { label: 'Long form discussions', value: 'long_form_discussions' },
  { label: 'Executive communications', value: 'executive_communications' },
  { label: 'Knowledge capture', value: 'knowledge_capture' },
  {
    label: 'Modern replacement for Google Groups',
    value: 'modern_replacement_for_google_groups',
  },
  {
    label: 'Modern platform for communities',
    value: 'modern_platform_for_communities',
  },
]
