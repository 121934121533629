import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Box from '../../components/box'
import { AuthProvider, AuthContext, SIGNUP_STEPS } from '../../context/auth'
import config from '../../utils/config'
import checkUtmParams from '../../utils/checkUtmParams'
import {
  AuthTitle,
  Button,
  Container,
  CopySubText,
  ErrorBanner,
  MobileAuthContainer,
} from '.'
import WorkspaceSetupSidebar from '../../components/workspaceSetupSidebar'
// import Select from '../../components/select'
import Input from '../../components/input'
import axios from 'axios'
import useOnEnterEventListener from '../../utils/hooks/useOnEnterEventListener'
import handleAuthNetworkError from '../../utils/handleAuthNetworkError'
import { departmentNames } from '../../utils/constants/signup'
import useHotjarScript from '../../utils/hooks/useHotjarScript'
import BasicSelect from '../../components/select/basicSelect'
import AuthSidebar from '../../components/authSidebar'
import { isMobileView } from '../../utils/isMobileView'
import MobileTopBar from '../../components/mobileTopBar'
// import Input from 'react-select/src/components/Input'

const OnboardingCallContainer = () => (
  <AuthProvider>
    <OnboardingCall />
  </AuthProvider>
)

const FormBox = styled.div`
  /* margin-top: 89px; */
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 769px) {
    margin-top: 0px;
  }
`

// const Header = styled.div`
//   font-family: ${props => props.theme.typography.fontFamilyGT};
//   font-weight: 700;
//   font-size: 20px;
//   line-height: 24px;
// `

// const Input = styled.input`
//   width: 260px;
//   height: 48px;
//   max-width: 90vw;
//   box-sizing: border-box;
//   background: ${props => props.theme.core.background.tertiary};
//   font-size: 16px;
//   line-height: 28px;
//   padding: ${props =>
//     `${props.theme.spacing(1.75)} ${props.theme.spacing(2.5)}`};
//   padding-right: 43px;
//   border: 2px solid transparent;
//   outline: none;
//   transition: all 100ms ease-in-out;
//   font-family: ${props => props.theme.typography.fontFamilyGTMono};
//   :focus {
//     background: ${props => props.theme.colors.white};
//     border: 2px solid ${props => props.theme.colors.black};
//   }
// `

const OnboardingCall = () => {
  const {
    signupStepsMap,
    token,
    setLoading,
    navigateAuth,
    userSession,
  } = useContext(AuthContext)

  const [error, setError] = useState(null)

  useEffect(() => {
    checkUtmParams()
  }, [])

  useHotjarScript()

  useEffect(() => {
    let intervalId = null
    if (token) {
      intervalId = setInterval(() => {
        axios
          .get(`${config.backendUri}/auth/session`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(result => {
            if (error) {
              setError(null)
            }
            if (
              result.data &&
              signupStepsMap.get(result.data.signupStep) ===
              SIGNUP_STEPS.COMPLETE
            ) {
              navigateAuth(signupStepsMap.get(result.data.signupStep))
            }
          })
          .catch(error => {
            setError(handleAuthNetworkError(error))
          })
      }, 2000)
    }

    return () => clearInterval(intervalId)
  }, [token, setError])

  const calUrl = `https://cal.com/leonardofed/onboarding-call?email=${encodeURIComponent(
    userSession ? userSession.email : null
  )}${userSession && userSession.firstName
      ? '&name=' +
      encodeURIComponent(userSession.firstName + ' ' + userSession.lastName)
      : ''
    }`

  if (isMobileView()) {
    return (
      <MobileAuthContainer>
        <SEO title="Complete profile" />
        <MobileTopBar>Onboarding</MobileTopBar>
        <div
          className="auth-mobile-body"
          style={{
            overflow: 'hidden',
            height: 'calc(100vh - 122px)',
            paddingBottom: '80px',
          }}
        >
          <FormBox>
            <Box>
              <AuthTitle>Book your onboarding call...</AuthTitle>
              <Box style={{ marginTop: '24px', marginBottom: '24px' }}>
                <iframe
                  src={calUrl}
                  frameborder="0"
                  allowfullscreen
                  style={{
                    width: 'calc(100vw - 24px - 24px)',
                    // give cal.com enough room without showing iframe scrollbars
                    height: 'calc(100vh - 144px)',
                  }}
                ></iframe>
              </Box>
              {error && (
                <Box style={{ marginTop: '24px' }}>
                  <ErrorBanner>{error}</ErrorBanner>
                </Box>
              )}
            </Box>
          </FormBox>
        </div>
      </MobileAuthContainer>
    )
  }

  return (
    <Layout style={{ height: '100vh' }}>
      <SEO title="Book a call" />
      {/* <WorkspaceSetupSidebar step={2} remainingTime="3 min" /> */}
      <Container>
        <AuthSidebar currentStepIndex={4} />
        <FormBox>
          <Box style={{ width: '800px' }}>
            <Box>
              <AuthTitle>Book your onboarding call...</AuthTitle>
              <Box
                style={{
                  marginTop: '16px',
                }}
              >
                <iframe
                  title="cal"
                  src={calUrl}
                  frameborder="0"
                  allowfullscreen
                  style={{
                    width: '100%',
                    height: '513px',
                  }}
                ></iframe>
              </Box>
            </Box>
            <Box
              style={{
                marginTop: '24px',
              }}
            />
            {/* <Button
              // style={{ width: '100%', height: '48px', marginTop: '16px' }}
              disabled={!firstName || !lastName || !jobRole || !department}
              onClick={() => handleSubmit()}
            >
              That's Me
            </Button> */}
            {error && (
              <Box style={{ marginTop: '16px' }}>
                <ErrorBanner>{error}</ErrorBanner>
              </Box>
            )}
          </Box>
        </FormBox>
      </Container>
    </Layout>
  )
}

export default OnboardingCallContainer
